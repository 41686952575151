import { AxiosApi } from '_services/Api';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import RequestLimitter from '_services/Api/_utils/RequestLimitter';
import { backendApi } from '_config';
import Csrf from './Csrf';

export type AxiosRequestShape = {
    url: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
};

function ensureValidUrl(url: string): string | undefined {
    let thisUrl: string;
    try {
        thisUrl = new URL(url).toString();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
        /**
         * Url may come in with base or without. For example it may be countries/list,
         * or it may be http(s)://{domain}/some/stuff
         * This logic ensures that backend api is used in the first instance
         */
        thisUrl = backendApi + url;
    }

    try {
        /**
         * After we've ensured baseUrl, we try again URL constructor validation, just in case
         */
        thisUrl = new URL(thisUrl).toString();
    } catch (e) {
        /**
         * If this fail now, we display an error because definetly there's something wrong
         */
        console.error('Invalid url used for api call', { url, e });
        return undefined;
    }

    return thisUrl;
}

export const callWithRequestLimiter = async ({
    url,
    method = 'GET',
    body,
    params,
    headers,
}: AxiosRequestShape): Promise<AxiosResponse> => {
    /**
     * Setting get jobs and remove jobs globally
     * To remove when unmounting of Social Component
     */
    if (!window?.removeRequestLimitterJobs) {
        window.removeRequestLimitterJobs = RequestLimitter.removeRequests;
    }

    const result = await RequestLimitter.pushRequest(
        async () =>
            await Csrf.getCookie().then(async () => {
                const validatedUrl = ensureValidUrl(url);
                if (!validatedUrl) {
                    return;
                }
                return await AxiosApi({
                    url: validatedUrl,
                    method,
                    data: body,
                    params,
                    headers,
                });
            }),
        new Date().getTime(),
    );

    return result;
};

const Api = {
    post: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'POST', url, body: data }),
    get: (
        url: string,
        data?: {
            params: AxiosRequestConfig['params'];
        },
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'GET', url, ...data }),
    put: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'PUT', url, body: data }),
    patch: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'PATCH', url, body: data }),
    delete: (url: string): Promise<AxiosResponse> =>
        callWithRequestLimiter({ url, method: 'DELETE' }),
};

export default Api;
