import * as Sentry from '@sentry/react';
import { version } from '_currentVersion';

const envType = process.env.REACT_APP_ENVIRONMENT ?? undefined;

export const sentryUrl = process.env.REACT_APP_SENTRY_DNS ?? null;

const SentryService = {
    init: (): void => {
        if (!sentryUrl) {
            return;
        }
        const appVersion = version
            ? 'kan-reactjs-pwa@' + version
            : 'no-version';
        Sentry.init({
            dsn: sentryUrl,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
                Sentry.captureConsoleIntegration({ levels: ['error'] }),
            ],
            environment: envType,
            normalizeDepth: 10, //Deep for redux state
            release: appVersion,
            tracesSampleRate: 0.1, // Capture 10% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%
            replaysOnErrorSampleRate: 1.0, //  The sample rate of 100% when sampling sessions where errors occur.
        });
    },
};

export default SentryService;
